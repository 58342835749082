.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  /*position: absolute;*/
}

body {
  background: #dbedf3;
  background: no-repeat
    url(https://s3.us-west-2.amazonaws.com/amirandjake-us-oregon/assets/jake-and-amir-blur.jpg);
  background: no-repeat
    url(https://s3-us-west-2.amazonaws.com/amirandjake-us-oregon/assets/jake-and-amir.jpg);
  background-size: 100% 100%;
  font-family: 'Hind', sans-serif;
  padding-bottom: 30px;
  /*padding-top: 20px;*/
  color: #333;
  /*color: white;*/
  background-attachment: fixed;
}

.top-bar .item a {
  height: 100%;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

p {
  text-shadow: 1px 1px 2px white, 0 0 1em white, 0 0 0.2em white;
}

.card p {
  text-shadow: none;
}

.top-bar {
  background-color: #283149;
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;
}

nav.top-bar div.item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5em;
  transition: background-color 0.5s;
  /*padding-left: 3px;*/
  /*padding-right: 3px;*/
  width: 100px;
}

nav.top-bar div.item:hover {
  background-color: #f73859;
}

nav.top-bar div.item a {
  color: white;
}

h1,
h2,
h3,
h4,
h5,
strong {
  /*color: #333;*/
  /*color: white;*/
  color: #f73859;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Artifika', serif;
}

span.highlight {
  font-weight: bold;
  background: #f73859;
}

h1 {
  text-align: center;
}

#content {
  margin-top: 30px;
}

.container {
  padding-bottom: 30px;
}

.button {
  background-color: #f73859;
}

.card {
  border-width: 0;
  border-left-width: 8px;
  border-left-color: #f73859;
  border-radius: 5px;
  border-style: solid;
  padding: 15px;
  box-shadow: 0 8px 6px -6px black;
  margin-bottom: 15px;
  color: #dbedf3;
  background-color: #404b69;
  background-image: linear-gradient(to top right, #404b69, #7585ba);
}

.card h1,
.card h2,
.card h3,
.card h4,
.card h5,
.card h5 {
  color: #dbedf3;
}

.card a {
  color: #dbedf3;
  text-decoration: underline;
}

a.button {
  text-decoration: none;
}

.card:hover {
  box-shadow: 0 8px 6px -6px black;
}

.card p,
label {
  color: #dbedf3;
}

.card iframe {
  border-radius: 5px;
  width: 100%;
  height: 400px;
}

.card .button {
  margin-right: 5px;
}

.card .full-script {
  background-color: #283149;
  padding: 10px;
  border-radius: 5px;
}

.card .snippet {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pagination {
  text-align: center;
}

.pagination li a {
  font-size: 2em;
  /*color: white;*/
}

#make-my-gif video {
  width: 98%;
}

#make-my-gif .title-container {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*#video-clip-preview {
	position: absolute;
	right: 0px;
	top: 50%;
}*/

#gif-start-time {
  width: 100%;
}

#gif-preview {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  transition: display 2s;
}

#gif-preview > div {
  display: block;
  margin-top: 10%;
}

#gif-preview img {
  height: auto;
  align-self: center;
  transition: display 2s;
  /*margin: auto;*/
}

#gif-preview .grid-x {
  display: flex;
}

.gif-preview-buttons {
  /*display: block;*/
  /*align-self: center;*/
}

#gifs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

#gifs .card {
  width: 400px;
  margin: 15px;
  /* display: inline-block; */
}

#faqs {
  display: flex;
  justify-content: center;
}

#faqs > div {
  width: 80%;
  padding: 15px;
}
